import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import useFitText from "use-fit-text";
export default function FullWidthImage(props) {
  const {
    height = 500,
    img,
    title,
    subheading,
    imgPosition = "center",
    color = "#00412D",
    darken = false,
    maxFont = 1000,
    removeMobileText = false,
  } = props;

  const { fontSize, ref } = useFitText({ maxFontSize: maxFont });

  return (
    <React.Fragment>
      <div
        className="margin-top-0"
        style={{
          display: "grid",
          alignItems: "center",
        }}
      >
        {img ? (
          img?.url ? (
            <img
              src={img}
              objectFit={"cover"}
              objectPosition={imgPosition}
              style={{
                gridArea: "1/1",
                // You can set a maximum height for the image, if you wish.
                height: height,
                width: "100%",
                filter: darken ? "brightness(50%)" : false,
              }}
              // You can optionally force an aspect ratio for the generated image
              aspectratio={3 / 1}
              // This is a presentational image, so the alt should be an empty string
              alt=""
              formats={["auto", "webp", "avif"]}
            />
          ) : (
            <GatsbyImage
              image={img}
              objectFit={"cover"}
              objectPosition={imgPosition}
              style={{
                gridArea: "1/1",
                // You can set a maximum height for the image, if you wish.
                maxHeight: height,
                height: height,
                filter: darken ? "brightness(50%)" : false,
              }}
              layout="fullWidth"
              // You can optionally force an aspect ratio for the generated image
              aspectratio={3 / 1}
              // This is a presentational image, so the alt should be an empty string
              alt=""
              formats={["auto", "webp", "avif"]}
            />
          )
        ) : (
          <div
            style={{
              width: "100%",
              minHeight: height,
              background: "black",
              gridArea: "1/1",
            }}
          ></div>
        )}
        {(title || subheading) && (
          <div
            className={`${removeMobileText ? "is-remove-mobile-text" : ""}`}
            style={{
              // By using the same grid area for both, they are stacked on top of each other
              gridArea: "1/1",
              position: "relative",
              // This centers the other elements inside the hero component
              placeItems: "center",
              display: "grid",
            }}
          >
            {/* Any content here will be centered in the component */}
            {title && (
              <h1
                ref={ref}
                style={{
                  fontSize,
                  width: "80%",
                  maxHeight: "30vh",
                  textAlign: "center",
                  fontFamily: "naguboty",
                  textTransform: "uppercase",
                  color: color,
                  lineHeight: "normal",
                  letterSpacing: ".6rem",
                }}
              >
                {title}
              </h1>
            )}
            {subheading && (
              <h3
                className="has-text-weight-bold is-size-5-mobile is-size-2-tablet is-size-2-widescreen"
                style={{
                  fontFamily: "athelas",
                  fontStyle: "italic",
                  fontWeight: "500",
                  color: "white",
                  padding: "0.25rem",
                }}
              >
                {subheading}
              </h3>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

FullWidthImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subheading: PropTypes.string,
  color: PropTypes.string,
  darken: PropTypes.bool,
};
