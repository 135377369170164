import React from "react";
import Layout from "../../components/Layout";
import FullWidthImage from "../../components/FullWidthImage";
import { getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql, Link } from "gatsby";

// eslint-disable-next-line
export default () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "contact.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const heroImage = getImage(data.file);

  return (
    <Layout>
      <FullWidthImage
        img={heroImage}
        title={"Kat in 't bakkie!"}
        subheading={"Jouw pakket is afgesloten"}
        height={"70vh"}
        color="#E8E100"
        darken={true}
      />
      <section
        className="section section--gradient"
        style={{ background: "#861c37" }}
      >
        <div className="container" id="main">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="columns">
                    <div className="column is-12">
                      <p className="theme-italic has-text-centered">
                        U spaart mee voor een onvergetelijk lustrum middels dit
                        spaarpakket.
                      </p>
                      <p className="theme-italic has-text-centered">
                        {" "}
                        Heb jij nog een vraag over jouw pakket? Kijk dan op de{" "}
                        <Link to="/vragen">veelgestelde vragen pagina</Link> of
                        stuur ons een bericht via e-mail (
                        <a href="mailto:secretaris@skadilustrum.nl">
                          secretaris@skadilustrum.nl
                        </a>
                        ) en wij zullen ervoor zorgen dat deze via de mail wordt
                        beantwoord ofwel wordt toegevoegd aan de vragenlijst!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
